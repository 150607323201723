<template>
  <v-dialog v-model="modalOpen" max-width="531" @click:outside="closeModal">
    <template v-slot:activator="{on}">
      <CreationButton v-on="on" @click="openModal" :type="decryptedType" />
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 v-if="id !== null" class="text-xl font-semibold">
          Edition d’enseignant
        </h1>
        <h1 v-else class="text-xl font-semibold">Ajout d’enseignant</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal" />
      </div>
      <div class="flex items-center mt-2">
        <div class="w-1/2 mr-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >Nom</label
          >
          <input
            type="text"
            v-model="nom"
            class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            @focus="clearNomError"
          />
          <span
            v-if="NomError"
            class="absolute -translate-y-4 flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ NomError }}
          </span>
        </div>
        <div class="w-1/2">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >Prénom</label
          >
          <input
            type="text"
            v-model="prenom"
            required
            class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            @focus="clearPrenomError"
          />
          <span
            v-if="PrenomError"
            class="absolute -translate-y-4 flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ PrenomError }}
          </span>
        </div>
      </div>
      <div class="flex items-center">
        <div class="w-1/2 mr-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >CIN officiel</label
          >
          <input
            type="text"
            v-model="cinoff"
            required
            class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            @focus="clearCinoffError"
          />
          <span
            v-if="CinoffError"
            class="absolute -translate-y-4 flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ CinoffError }}
          </span>
        </div>
        <div class="w-1/2">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >Email</label
          >
          <input
            type="email"
            v-model="mail"
            class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            @focus="clearMailError"
          />
          <span
            v-if="MailError"
            class="absolute -translate-y-4 flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ MailError }}
          </span>
        </div>
      </div>
      <div class="flex items-center">
        <div class="w-1/2 mr-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >Grade</label
          >
          <select
            v-model="grade"
            required
            class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            @change="clearGradeError"
          >
            <option value="" disabled selected>Sélectionnez le grade</option>
            <option
              v-for="gradeElt in listeGrades"
              :key="gradeElt.code"
              :value="gradeElt.code"
            >
              {{ gradeElt.label }}
            </option>
          </select>
          <span
            v-if="GradeError"
            class="absolute -translate-y-4 flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ GradeError }}
          </span>
        </div>
        <div class="w-1/2 mb-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >Genre</label
          >
          <select
            v-model="selectedGenre"
            required
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
            @change="handleSemestreChange"
          >
            <option value="" disabled hidden>Veuillez sélectionner</option>
            <option value="M">Homme</option>
            <option value="F">Femme</option>
          </select>
          <span
            v-if="GenreError"
            class="absolute flex items-center text-red-500 te0123xt-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ GenreError }}
          </span>
        </div>
      </div>

      <div class="flex items-center">
        <div class="w-1/2 mr-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >Département</label
          >
          <select
            v-model="selectedDepartement"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
            @change="handleDepartmentChange"
          >
            <option value="" disabled hidden>Veuillez sélectionner</option>
            <option
              v-for="departement in Departements"
              :key="departement.id"
              :value="departement.id"
            >
              {{ departement.label }}
            </option>
          </select>
          <span
            v-if="DepError"
            class="absolute flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ DepError }}
          </span>
        </div>
        <div class="w-1/2">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
          >
            Situation
          </label>
          <select
            v-model="selectedSituation"
            required
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
            @change="handleSemestreChange"
          >
            <option value="" disabled hidden>Veuillez sélectionner</option>
            <option value="Permanent">Permanent</option>
            <option value="vacataire">vacataire</option>
            <option v-if="id" value="md">Mise en disponibilité</option>
          </select>
          <span
            v-if="SituationError"
            class="absolute flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ SituationError }}
          </span>
        </div>
      </div>

      <div v-if="showMoreInfo">
        <div class="w-full mt-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
            >indentifiant unique</label
          >
          <input
            type="text"
            v-model="idUnique"
            class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
          />
        </div>
        <div class="flex items-center mt-2">
          <div class="w-1/2 mr-4">
            <label
              class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
              >Nom en arabe</label
            >
            <input
              type="text"
              v-model="nomArabe"
              class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            />
          </div>
          <div class="w-1/2">
            <label
              class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
              >Prénom en arabe</label
            >
            <input
              type="text"
              v-model="prenomArabe"
              class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            />
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-1/2 mr-4">
            <label
              class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
              >Login Didousoft</label
            >
            <input
              type="text"
              v-model="cin"
              class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            />
          </div>
          <div class="w-1/2">
            <label
              class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
              >Date de naissance
            </label>
            <input
              type="date"
              v-model="date_naissance"
              class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none text-gray-400"
            />
          </div>
        </div>
        <div class="flex items-center">
          <div class="w-1/2 mr-4">
            <label
              class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
              >Lieu de naissance en arabe
            </label>
            <input
              type="text"
              v-model="lieuArabe"
              class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            />
          </div>
          <div class="w-1/2">
            <label
              class="mb-2 block font-inter text-base font-normal leading-6 text-left text-gray-900"
              >Lieu de naissance en Français
            </label>
            <input
              type="text"
              v-model="lieuFr"
              class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
            />
          </div>
        </div>
        <div class="flex justify-center">
          <img :src="paper" class="w-4 h-4" />
          <span class="text-gray-400">Pièce jointe</span>
        </div>
        <span class="text-xs font-extralight text-gray-400 w-10 h-10">
          Joignez jusqu'à 2 fichiers à la fois, la taille totale du fichier ne
          doit pas dépasser 25Mo.
        </span>
        <div class="flex items-center mt-1">
          <input
            type="text"
            id="fileName1"
            class="w-4/6 rounded-l-full border border-gray-300 h-10 text-gray-400 text-sm text-center"
            placeholder="Justificatif 1"
          />
          <label
            for="fileInput1"
            class="w-2/6 bg-cyan-600 rounded-r-full border border-gray-300 h-10 text-white flex items-center justify-center cursor-pointer gap-1"
          >
            <img :src="upload" class="w-4 h-4" />
            <span class="flex items-center max-sm:text-xs"
              >Choisir le fichier</span
            >
            <input
              id="fileInput1"
              type="file"
              class="hidden"
              @change="loadjustif1"
            />
          </label>
        </div>
        <div class="flex items-center mt-2">
          <input
            type="text"
            id="fileName2"
            class="w-4/6 rounded-l-full border border-gray-300 h-10 text-gray-400 text-sm text-center"
            placeholder="Justificatif 2"
          />

          <label
            for="fileInput2"
            class="w-2/6 bg-cyan-600 rounded-r-full h-10 text-white flex items-center justify-center cursor-pointer gap-1"
          >
            <img :src="upload" class="w-4 h-4" />
            <span class="flex items-center max-sm:text-xs"
              >Choisir le fichier</span
            >
            <input
              id="fileInput2"
              type="file"
              class="hidden"
              @change="loadjustif2"
            />
          </label>
        </div>
      </div>

      <v-card-actions class="flex justify-between mt-4">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal"
        >
          Annuler
        </button>
        <div class="flex gap-2">
          <button
            v-if="!showMoreInfo"
            class="borderInfo bg-neutral-50 border rounded-full md:px-6 max-sm:px-2 outline-none text-blue-700 font-inter text-sm font-semibold leading-10 tracking-normal text-center"
            @click="toggleMoreInfo"
          >
            Plus d’infos
          </button>
          <button
            class="bg-blue-700 rounded-full md:px-6 max-sm:px-2 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
            @click="addOrUpdateEnseignant"
          >
            Sauvegarder
          </button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import upload from '../../../assets/upload.png';
import redinfo from '../../../assets/redinfo.svg';
import plus from '../../../assets/plus-sm.png';
import paper from '../../../assets/paper-clip.png';
import Button from '../../../assets/Button.png';
import apolloClient from '../../../apolloClient';
import CryptoJS from 'crypto-js';
import CreationButton from '../../Buttons/CreationButton/CreationButton.vue';
import {Get_All_Dep} from '../ModalClasse/ModalClasse.service';
import {
  ADD_ENSEIGNANT,
  ENSEIGNANT_GRADES,
  UPDATE_ENSEIGNANT,
} from '../ModalEnseignant/ModalEnseignant.service.js';

export default {
  name: 'ModalEnseignant',
  components: {
    CreationButton,
  },
  data() {
    return {
      id: null,
      redinfo,
      upload,
      modalOpen: false,
      plus,
      paper,
      Button,
      nom: null,
      nomArabe: null,
      prenom: null,
      prenomArabe: null,
      cin: null,
      indentifiant: null,
      cinoff: null,
      mail: null,
      date_naissance: null,
      lieuArabe: null,
      lieuFr: null,
      grade: '',
      gradeArabe: null,
      codeGrade: null,
      idUnique: null,
      selectedSituation: 'Permanent',
      selectedGenre: 'M',
      selectedDepartement: '',
      showMoreInfo: false,
      isEmailValid: true,
      decryptedType: '',
      NomError: '',
      PrenomError: '',
      CinoffError: '',
      MailError: '',
      GradeError: '',
      DepError: '',
      SituationError: '',
      GenreError: '',
      Departements: [],
      justifi1Base64: null,
      justifi2Base64: null,
      base64String: '',
      label: '',
      listeGrades: ENSEIGNANT_GRADES,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    openEditionModal(id, enseignant) {
      this.id = id;
      this.nom = enseignant.nom;
      this.prenom = enseignant.prenom;
      this.cinoff = enseignant.cin_off;
      this.mail = enseignant.mail;
      this.grade = enseignant.grade_fr;
      this.selectedGenre = enseignant.gendre;
      this.selectedSituation = enseignant.situation;
      this.selectedDepartement = enseignant.dep_id;
      this.nomArabe = enseignant.nom_ar;
      this.prenomArabe = enseignant.prenom_ar;
      this.cin = enseignant.cin;
      this.date_naissance = enseignant.date_n_ar;
      this.lieuArabe = enseignant.lieu_ar;
      this.lieuFr = enseignant.lieu_fr;
      this.modalOpen = true;
    },
    closeModal() {
      this.showMoreInfo = false;
      this.modalOpen = false;
      this.id = null;
      this.nom = null;
      this.prenom = null;
      this.cinoff = null;
      this.mail = null;
      this.grade = '';
      this.selectedGenre = '';
      this.selectedSituation = '';
      this.selectedDepartement = '';
      this.nomArabe = null;
      this.prenomArabe = null;
      this.cin = null;
      this.date_naissance = null;
      this.lieuArabe = null;
      this.lieuFr = null;
      this.justifi1Base64 = null;
      this.justifi2Base64 = null;
    },
    toggleMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    validateNom(value) {
      if (!value) {
        return 'Veuillez saisir un nom.';
      }
      return '';
    },
    validatePrenom(value) {
      if (!value) {
        return 'Veuillez saisir un prenom.';
      }
      return '';
    },
    validateCinoff(value) {
      if (!isNaN(value) && value.length !== 8) {
        return 'Le CIN ne doit pas dépasser 8 caractères.';
      }
      return '';
    },
    validateMail(value) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        return 'Adresse e-mail invalide.';
      }
      return '';
    },
    validateGrade(value) {
      if (!value) {
        return 'Veuillez saisir un grade.';
      }
      return '';
    },
    validateDep(value) {
      if (!value) {
        return 'Veuillez selectionner un departement.';
      }
      return '';
    },
    validateSituation(value) {
      if (value === null || value === undefined) {
        return 'Veuillez sélectionner une situation.';
      }
      return '';
    },
    validateGenre(value) {
      if (!value) {
        return 'Veuillez selectionner un genre.';
      }
      return '';
    },
    clearNomError() {
      this.NomError = '';
    },
    clearPrenomError() {
      this.PrenomError = '';
    },
    clearCinoffError() {
      this.CinoffError = '';
    },
    clearMailError() {
      this.MailError = '';
    },
    clearGradeError() {
      this.GradeError = '';
    },
    clearDepError() {
      this.DepError = '';
    },
    clearSituationError() {
      this.SituationError = '';
    },
    clearGenreError() {
      this.GenreError = '';
    },
    async addOrUpdateEnseignant() {
      try {
        this.NomError = this.validateNom(this.nom);
        this.PrenomError = this.validatePrenom(this.prenom);
        this.CinoffError = this.validateCinoff(this.cinoff);
        this.MailError = this.validateMail(this.mail);
        this.GradeError = this.validateGrade(this.grade);
        this.DepError = this.validateDep(this.selectedDepartement);
        this.SituationError = this.validateSituation(this.selectedSituation);
        this.GenreError = this.validateGenre(this.selectedGenre);
        if (
          this.NomError ||
          this.PrenomError ||
          this.CinoffError ||
          this.MailError ||
          this.GradeError ||
          this.DepError ||
          this.SituationError ||
          this.GenreError
        ) {
          return;
        }
        const datenais = new Date(this.date_naissance);
        const today = new Date();
        const age = today.getFullYear() - datenais.getFullYear();
        const monthDiff = today.getMonth() - datenais.getMonth();

        if (age < 20 || (age === 20 && monthDiff < 0)) {
          alert("L'enseignant doit avoir au moins 20 ans.");
          return;
        }

        await apolloClient.mutate({
          mutation: this.id ? UPDATE_ENSEIGNANT : ADD_ENSEIGNANT,
          variables: {
            id: this.id || undefined,
            nom: this.nom,
            prenom: this.prenom,
            cin_off: this.cinoff,
            mail: this.mail,
            grade_fr: this.grade,
            gendre: this.selectedGenre,
            departement: '',
            situation: this.selectedSituation,
            indentifiant_unique: this.idUnique,
            nom_ar: this.nomArabe,
            prenom_ar: this.prenomArabe,
            cin: this.cin,
            date_n_ar: this.date_naissance,
            lieu_ar: this.lieuArabe,
            lieu_fr: this.lieuFr,
            justification1: this.justifi1Base64,
            justification2: this.justifi2Base64,
            dep_id: this.selectedDepartement,
          },
        });

        this.nom = '';
        this.prenom = '';
        this.mail = '';
        this.cinoff = '';
        this.grade = '';
        this.selectedGenre = '';
        this.selectedSituation = 'vacataire';
        this.selectedDepartement = '';
        this.nomArabe = '';
        this.idUnique = null;
        this.$emit('RefreshTable');
        this.closeModal();
      } catch (error) {
        console.error("Erreur lors de l'ajout de enseignant:", error);
        alert("Erreur lors de l'ajout de enseignant");
      }
    },
    async getDep() {
      try {
        const response = await apolloClient.query({
          query: Get_All_Dep,
        });
        this.Departements = response.data.getAllDepartements;
      } catch (error) {
        console.error('Error fetching Departements:', error);
      }
    },

    loadjustif1(event) {
      this.handleFileUpload(event, 'fileName1');
    },
    loadjustif2(event) {
      this.handleFileUpload(event, 'fileName2');
    },
    handleFileUpload(event, fileNameId) {
      const input = event.target;
      const fileNameInput = document.getElementById(fileNameId);
      if (input.files && input.files[0]) {
        const file = input.files[0];
        fileNameInput.value = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          if (fileNameId == 'fileName1') {
            this.justifi1Base64 = base64String;
          } else {
            this.justifi2Base64 = base64String;
          }
        };
        reader.readAsDataURL(file);
      }
    },
  },
  mounted() {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
    this.getDep();
  },
};
</script>
<style scoped>
.borderInfo {
  border-color: #1d4ed8 !important;
}
</style>
