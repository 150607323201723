<template>
  <div class="bg-gray-50">
    <div
      class="pt-16 duration-500"
      :class="!showCard ? 'md:mx-16' : 'md:mx-28'"
    >
      <div class="w-full h-full pt-20 flex flex-col banner bg-cover rounded-lg">
        <div class="flex items-center justify-between">
          <h1 class="text-4xl pl-10 text-white font-Inter font-extrabold">
            Emplois de temps
          </h1>
          <div class="pr-12 flex gap-4">
            <ModalEmploisDeTemps
              @EmploiAdded="refreshData"
              v-if="!selectedClassLabel"
            />
            <div v-if="['dir_etu', 'dir_dept'].includes(userType)">
              <gradient-button
                type="normal"
                color="green"
                @click="isPublierVisible = !isPublierVisible"
              >
                Diffuser
              </gradient-button>
              <modal-publier-emploi
                v-model="isPublierVisible"
                @proceedPublier="diffuserEmploi"
              />
            </div>
          </div>
        </div>
        <FilterByYear
          class="pl-10 mt-6 mb-5"
          v-model="selectedYear"
          v-if="!selectedClassLabel"
          :annees="annees"
        />
        <div v-else class="pl-10 mt-6 mb-20"></div>
      </div>
      <div v-if="loading" class="text-center mt-8">
        <p class="text-lg font-semibold text-gray-700 mb-4">
          Chargement d'emploi en cours...
        </p>
        <div
          class="inline-block animate-spin rounded-full h-8 w-8 border-t-4 border-blue-500"
        ></div>
      </div>
      <div v-else>
        <div v-if="showCard" class="bg-white rounded-tl-10 rounded-tr-10">
          <div class="py-4 px-4">
            <SearchFieldEmploiTemps
              @search="handleSearch"
              :semestres="semestres"
              :DepartementOptions="departements"
            />
          </div>
          <div class="flex justify-end items-center pr-20">
            <span class="pr-2 text-base font-semibold">Archivé</span>
            <Toggle
              :value="isArchived"
              textYes="Oui"
              textNo="Non"
              @toggle-input="toggleDisplayArchived"
            />
          </div>
          <div class="p-5 max-h-175 overflow-y-auto">
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              <div
                v-for="(emploi, index) in filterEmplois"
                :key="index"
                class="pb-5 px-3"
              >
                <CarteEmploiTemps
                  :nomDep="emploi.departement.label"
                  :group="emploi.classe.label"
                  :updatedDate="emploi.updatedDate"
                  :acces="emploi.acces"
                  :emploiId="emploi.id"
                  :nbSeances="emploi.nb_seances"
                  :archived="emploi.archived"
                  :nbVolHoraire="calculateTotalVolHoraire(emploi)"
                  :niveau="emploi.classe.niveau"
                  @toggleArchive="toggleArchive"
                  @refreshData="refreshData"
                  @access-change="toggleAcces"
                  :semestre="emploi.semestre"
                  @toggleSuppression="toggleSuppression"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalErreur
      v-if="showError"
      @toggle-erreur="toggleToggleError"
      :reason="errorReason"
    />
    <modal-success v-if="showSuccess" @toggleSuccess="showSuccess = false" />
    <ColoredModal
      v-if="confirmSuppressionOpen"
      title="Confirmez la suppression"
      message="Êtes-vous sûr d'avoir voulu effectuer cette opération?<br> La suppression est définitive."
      @b-confirm="deleteEmploi"
      @b-reject="confirmSuppressionOpen = false"
    />
    <ModalDupliquerEmploi
      :is-open="isDuplicateModalOpen"
      @close="closeDuplicateModal"
      @duplicate="handleDuplicate"
    />
  </div>
</template>

<script>
import ModalEmploisDeTemps from '../../components/Modals/ModalEmploisDeTemps/ModalEmploisDeTemps.vue';
import SearchFieldEmploiTemps from '../../components/SearchField/SearchFieldEmploiTemps/SearchFieldEmploiTemps.vue';
import apolloClient from '../../apolloClient';
import {
  GET_ALL_EMPLOIS,
  TOGGLE_ACCES,
  GET_ANNEE_UNIVERSITAIRES,
  ARCHIVE_EMPLOI,
  DELETE_EMPLOI,
  DIFFUSER_EMPLOI,
} from './EmploisDeTemps.service';

import CarteEmploiTemps from '../../components/CarteEmploiTemps/CarteEmploiTemps.vue';
import Toggle from '../../components/Toggle/Toggle.vue';
import {GET_DEPARTEMENTS} from '../../components/Modals/ModalEmploisDeTemps/ModalEmploisDeTemps.service';
import FilterByYear from '../../components/FilterByYear/FilterByYear.vue';
import {GET_DEROULEMENT_SEANCE_BY_ETAB} from '../Parametre/Parametre.services';
import ModalErreur from '@/components/Popup/Erreurs/ModalErreur.vue';
import ColoredModal from '../../components/Popup/ColoredModal/ColoredModal.vue';

import ModalDupliquerEmploi from '@/components/Modals/ModalDupliquerEmploi/ModalDupliquerEmploi.vue';
import {
  getCurrentSemestre,
  getCurrentYear,
  getDecryptedType,
} from '../spa.services';
import GradientButton from '../../components/Buttons/GradientButton/GradientButton.vue';
import ModalPublierEmploi from '@/components/Modals/ModalPublierEmploi/ModalPublierEmploi.vue';
import ModalSuccess from '../../components/Popup/Success/ModalSuccess.vue';

export default {
  components: {
    ModalEmploisDeTemps,
    SearchFieldEmploiTemps,
    CarteEmploiTemps,
    Toggle,
    FilterByYear,
    ModalErreur,
    ColoredModal,
    ModalDupliquerEmploi,
    GradientButton,
    ModalPublierEmploi,
    ModalSuccess,
  },
  data() {
    return {
      emplois: [],
      searchQuery: '',
      showCard: true,
      semestres: ['Tout', 'S1', 'S2'],
      selectedSemestre: 'Tout',
      departements: [],
      selectedDepartement: 'Tout département',
      selectedYear: '',
      selectedClassLabel: null,
      loading: true,
      duree_seance: null,
      showError: false,
      errorReason: '',
      showSuccess: false,
      annees: [],
      idEmp: null,
      isArchived: false,
      confirmSuppressionOpen: false,
      modalDupliquerVisible: false,
      emploiIdToDuplicate: null,
      isPublierVisible: false,
      userType: '',
    };
  },
  watch: {
    selectedYear() {
      this.fetchEmploisTemps();
    },
  },
  methods: {
    diffuserEmploi() {
      apolloClient
        .mutate({
          mutation: DIFFUSER_EMPLOI,
          variables: {
            annee_universitaire: this.selectedYear,
          },
        })
        .then(({data}) => {
          if (data.diffuserEmplois.success == false) {
            this.showError = true;
            this.errorReason = data.diffuserEmplois.message;
          } else {
            this.showSuccess = true;
          }
        });
    },
    getCurrentYear() {
      const currentYear = new Date().getFullYear();
      return `${currentYear - 1}-${currentYear}`;
    },
    async refreshData() {
      this.fetchEmploisTemps();
    },
    async fetchEmploisTemps() {
      try {
        const response = await apolloClient.query({
          query: GET_ALL_EMPLOIS,
          fetchPolicy: 'no-cache',
        });
        this.emplois = response.data.getAllEmploisTemps;
        if (this.isArchived === false) {
          this.afficherActifs();
        } else if (this.isArchived) {
          this.afficherArchives();
        }
        const res = await apolloClient.query({
          query: GET_DEROULEMENT_SEANCE_BY_ETAB,
        });
        this.duree_seance = res.data.getDeroulementSeanceByEtab.duree_seance;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching:', error);
        this.loading = false;
      }
    },
    async toggleAcces(EmploiId) {
      try {
        const response = await apolloClient.mutate({
          mutation: TOGGLE_ACCES,
          variables: {
            id: EmploiId,
          },
        });
        if (response.data && response.data.toggleAcces != null) {
          const indexCardEmploi = this.emplois.findIndex(
            e => e.id === EmploiId
          );
          if (indexCardEmploi >= 0) {
            this.emplois[indexCardEmploi].acces =
              response.data.toggleAcces == 1;
          }
        } else {
          console.error('toggle failed');
        }
      } catch (error) {
        console.error('error toggling acces emploi : ', error);
      }
    },
    async getDepartements() {
      try {
        const response = await apolloClient.query({
          query: GET_DEPARTEMENTS,
        });
        this.departements = response.data.getAllDepartements;
      } catch (error) {
        console.error('erreur de recuperation departements :', error);
      }
    },
    handleSearch(searchData) {
      this.searchQuery = searchData.searchQuery;
      this.selectedSemestre = searchData.selectedSemestre;
      this.selectedDepartement = searchData.selectedDepartement;
    },
    emploisPresents() {
      const currentYear = new Date().getFullYear();
      this.selectedYear = `${currentYear - 1}-${currentYear}`;
    },

    getNombreDeSeances(emploi) {
      return emploi.seancesEmploi.length;
    },
    calculateTotalVolHoraire(emploi) {
      const duree_seance = this.duree_seance;
      const nbr_de_seances = emploi.nb_seances;
      switch (duree_seance) {
        case '1h':
          return nbr_de_seances * 1;
        case '1h30':
          return nbr_de_seances * 1.5;
        case '2h':
          return nbr_de_seances * 2;
        default:
          return 0;
      }
    },
    toggleToggleError() {
      this.showError = !this.showError;
    },
    async getAnneeUniversitaires() {
      try {
        const response = await apolloClient.query({
          query: GET_ANNEE_UNIVERSITAIRES,
        });
        this.annees = response.data.getAnneeUniversitaires;
      } catch (error) {
        console.error(error);
      }
    },
    toggleArchive(id_emp) {
      this.idEmp = id_emp;
      this.archiveEmploi();
    },
    toggleDisplayArchived() {
      this.isArchived = !this.isArchived;
      this.fetchEmploisTemps();
    },
    afficherActifs() {
      let actifs = this.emplois;
      actifs = actifs.filter(emploi => !emploi.archived);
      this.emplois = actifs;
    },
    afficherArchives() {
      let archives = this.emplois;
      archives = archives.filter(emploi => emploi.archived);
      this.emplois = archives;
    },
    async archiveEmploi() {
      if (this.idEmp) {
        try {
          const response = await apolloClient.mutate({
            mutation: ARCHIVE_EMPLOI,
            variables: {
              id: this.idEmp,
            },
          });
          if (response.data && response.data.archiveEmploi) {
            this.refreshData();
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    toggleSuppression(idEmploi) {
      this.idEmp = idEmploi;
      this.confirmSuppressionOpen = !this.confirmSuppressionOpen;
    },
    async deleteEmploi() {
      try {
        const response = await apolloClient.mutate({
          mutation: DELETE_EMPLOI,
          variables: {
            id: this.idEmp,
          },
        });
        if (response.data && response.data.deleteEmploi) {
          this.refreshData();
          this.confirmSuppressionOpen = false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    openModalDupliquer(id) {
      this.emploiIdToDuplicate = id;
      this.modalDupliquerVisible = true;
    },
    closeModalDupliquer() {
      this.modalDupliquerVisible = false;
    },
    handleDuplicateSuccess(data) {
      if (data.success) {
        this.refreshData();
      } else {
        console.error(data.message);
      }
      this.closeModalDupliquer();
    },
  },
  computed: {
    filterEmplois() {
      let filteredEmplois = this.emplois;
      if (this.searchQuery) {
        const query = String(this.searchQuery).toLowerCase().trim();
        filteredEmplois = filteredEmplois.filter(
          emploi =>
            emploi.classe.label &&
            emploi.classe.label.toLowerCase().includes(query)
        );
      }
      if (this.selectedSemestre && this.selectedSemestre != 'Tout') {
        filteredEmplois = filteredEmplois.filter(
          emploi => emploi.semestre === this.selectedSemestre
        );
      }
      if (
        this.selectedDepartement &&
        this.selectedDepartement != 'Tout département'
      ) {
        filteredEmplois = filteredEmplois.filter(
          emploi => emploi.departement.label === this.selectedDepartement
        );
      }
      // ! ça peux provoquer un grand lenteur de chargement au futur
      if (this.selectedYear) {
        filteredEmplois = filteredEmplois.filter(
          emploi => emploi.annee_universitaire === this.selectedYear
        );
      }
      return filteredEmplois;
    },
    nbr_de_seances() {
      const nbrDeSeances = {};
      this.emplois.forEach(emploi => {
        nbrDeSeances[emploi.id] = emploi.seancesEmploi.length;
      });
      return nbrDeSeances;
    },
  },
  mounted() {
    this.fetchEmploisTemps();
    this.getDepartements();
    this.emploisPresents();
    this.getAnneeUniversitaires();
    this.selectedYear = getCurrentYear();
    this.userType = getDecryptedType();
    this.selectedSemestre = getCurrentSemestre();
  },
};
</script>

<style>
.banner {
  background-image: url('../../assets/emplois.jpg');
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
