<template>
  <div>
    <div v-if="isFullComponent() && currentUrl && !isNotFoundPage()">
      <RouterLayout>
        <router-view :currentUrl="currentUrl" />
      </RouterLayout>
    </div>

    <router-view v-else />
  </div>
</template>

<script>
import RouterLayout from './SPA/RouterLayout/RouterLayout.vue';
export default {
  name: 'App',
  data() {
    return {
      currentUrl: '',
    };
  },
  components: {
    RouterLayout,
  },
  watch: {
    $route(pathUrl) {
      this.currentUrl = pathUrl.path;
    },
  },
  methods: {
    isNotFoundPage() {
      return this.$route.name === 'NotFound';
    },
    isFullComponent() {
      return (
        this.currentUrl !== '/' &&
        this.currentUrl.indexOf('emploi-classe-public') == -1 &&
        this.currentUrl.indexOf('emploi-ens-public') == -1 &&
        this.currentUrl.indexOf('emploi-enseignants-publipostage') == -1 &&
        this.currentUrl.indexOf('print') == -1
      );
    },
  },
};
</script>
