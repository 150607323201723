<template>
  <div class="my-4">
    <label class="mb-2">{{ title }}</label>
    <select
      class="w-full appearance-none border border-gray-300 rounded-md py-2 pl-4 pr-10 outline-none"
      v-model="selectedOption"
    >
      <option value="" disabled hidden selected>Veuillez sélectionner</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.id"
      >
        {{ option.label }}
      </option>
    </select>
    <span v-if="error" class="absolute flex items-center text-red-500 text-xs">
      <img :src="redinfo" class="h-3 w-3 mr-1" />
      {{ error }}
    </span>
  </div>
</template>
<script>
import redinfo from '../../assets/redinfo.svg';
export default {
  props: {
    title: {
      Type: String,
    },
    options: {
      Type: Array,
      default: () => [],
    },
    error: {
      Type: String,
    },
    modelValue: {
      default: null,
    },
  },
  data() {
    return {
      redinfo,
    };
  },
  computed: {
    selectedOption: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    selectOption(event) {
      const selectedOption = event.target.value;
      this.selectedOption = selectedOption;
      this.$emit('option-selected', selectedOption);
    },
  },
};
</script>
