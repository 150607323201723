<template>
  <div class="bg-gray-50">
    <div class="pt-16 md:mx-28">
      <div
        class="w-full h-full py-20 flex flex-col banner-bg bg-cover rounded-lg"
      >
        <div class="flex items-center justify-between">
          <h1 class="text-4xl pl-10 text-white font-Inter font-extrabold">
            Gestion des enseignants
          </h1>
          <div class="pr-12">
            <ModalEnseignant
              ref="ModalEnseignant"
              @RefreshTable="refreshData"
              :id="selectedItemId"
            />
            <div class="absolute pt-6">
              <ModalImportEnseignant
                v-if="isEditAllowed"
                @toggle-erreur="toggleErreur"
                @refresh-view="refreshData"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-tl-10 rounded-tr-10">
        <div class="py-4 px-4">
          <SearchField @search="handleSearch" />
        </div>
        <div class="flex justify-space-between p-4">
          <div>
            <GradientButton
              class="rounded-lg me-2"
              type="soft"
              color="green"
              title="télécharger"
              @click="exporterEnseignants()"
            >
              <VIcon> mdi-file-excel </VIcon>
            </GradientButton>
          </div>
          <div class="w-60">
            <v-select
              v-model="itemsPerPage"
              label="Nombres d'élements par page"
              :items="perPageOptions"
              variant="outlined"
              class="h-16 text-sm text-slate-600"
            ></v-select>
          </div>
        </div>
        <v-data-table
          :headers="headers"
          class="elevation-1 rounded"
          :items="filteredEnseignants"
          no-data-text="Chargement en cours"
          :items-per-page="itemsPerPage"
          :page="page"
        >
          <template v-slot:[`item.nom`]="{value}">
            <p class="font-bold text-capitalize">{{ value }}</p>
          </template>

          <template v-slot:[`item.prenom`]="{item}">
            <p class="font-bold">{{ item.prenom }}</p>
          </template>

          <template v-slot:[`item.departement.label`]="{item}">
            <div class="flex align-center">
              <ButtonDisplay :label="item.departement.label" />
            </div>
          </template>

          <template v-slot:[`item.gendre`]="{item}">
            <div class="flex align-center">
              <GenreComponent :genre="item.gendre" />
            </div>
          </template>

          <template v-slot:[`item.grade_trad`]="{item}">
            <p class="font-normal">{{ item.grade_trad }}</p>
          </template>

          <template v-slot:[`item.situation`]="{item}">
            <p class="font-normal">
              {{
                item.situation == 'md'
                  ? 'Mise en disponibilité'
                  : item.situation
              }}
            </p>
          </template>

          <template v-slot:[`item.id`]="{item}">
            <div class="relative">
              <div class="flex items-center justify-end z-10">
                <router-link
                  :to="`/emploi-enseignant?id=${item.id}`"
                  target="_blank"
                >
                  <GradientButton
                    text="Emploi"
                    class="mr-2 shadow-none min-h-10"
                  />
                </router-link>
                <modal-pfe
                  class="mr-2"
                  :idEnseignant="item.id"
                  :nom="item.nom"
                  :prenom="item.prenom"
                ></modal-pfe>
                <CarteDetailsEnseignant
                  @showDetails="getEnseignantById(item.id)"
                  :nom_ar="selectedEnseignant.nom_ar"
                  :prenom_ar="selectedEnseignant.prenom_ar"
                  :identifiant_unique="selectedEnseignant.indentifiant_unique"
                  :date_naissance="selectedEnseignant.date_n_ar"
                  :lieu_naissance="selectedEnseignant.lieu_fr"
                  :lieu_naissance_ar="selectedEnseignant.lieu_ar"
                  :justif1="selectedEnseignant.justificatif1"
                  :justif2="selectedEnseignant.justificatif2"
                />
                <img
                  v-if="
                    decryptedType === 'dir_dept' || decryptedType === 'dir_etu'
                  "
                  :src="kebabIcon"
                  alt=""
                  class="cursor-pointer"
                  @click="toggleKebabMenu(item.id)"
                />
              </div>
              <KebabMenu
                :dataOpenKebabMenu="openKebabMenu"
                v-if="selectedItemId === item.id"
                :menuItems="menuItems"
                class="absolute right-1 top-10 z-30"
                @toggleSupression="toggleSupression"
              />
            </div>
          </template>

          <template v-slot:bottom>
            <div class="flex items-center justify-end pt-2 m-4">
              <v-pagination
                variant="flat"
                prev-icon="mdi-arrow-left"
                next-icon="mdi-arrow-right"
                active-color="blue-700 text-white"
                :total-visible="5"
                size="40"
                rounded="circle"
                v-model="page"
                :length="pageCount"
              />
            </div>
          </template>
        </v-data-table>
        <ColoredModal
          v-if="confirmSupressionOpen"
          title="Confirmez la suppression"
          message="Êtes-vous sûr d'avoir voulu effectuer cette opération?<br> La suppression est définitive."
          @b-confirm="deleteEnseignant(selectedItemId)"
          @b-reject="confirmSupressionOpen = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModalImportEnseignant from '@/components/Modals/ModalEnseignant/ModalImportEnseignant.vue';
import apolloClient from '../../apolloClient';
import editionIcon from '../../assets/editionIcon.svg';
import ButtonDisplay from '../../components/Buttons/ButtonDisplay.vue';
import GenreComponent from '../../components/GenreComponent/GenreComponent.vue';
import info from '../../assets/info.svg';
import kebabIcon from '../../assets/kebabIconGray.svg';
import upload from '../../assets/upload.svg';
import ModalEnseignant from '../../components/Modals/ModalEnseignant/ModalEnseignant.vue';
import GradientButton from '@/components/Buttons/GradientButton/GradientButton.vue';
import CryptoJS from 'crypto-js';
import SearchField from '../../components/SearchField/SearchField.vue';
import {editAllowed} from '../spa.services';

import {
  FETCH_ENSEIGNANTS,
  GETDEPARTEMENT_BY_ID_DIRECTEUR,
  GET_ENSEIGNANT_BY_ID,
  DELETE_ENSEIGNANT,
} from './EnseignantList.service.js';
import CarteDetailsEnseignant from '../../components/CarteDetailsEnseignant/CarteDetailsEnseignant.vue';
import KebabMenu from '../../components/KebabMenu/KebabMenu.vue';
import ColoredModal from '../../components/Popup/ColoredModal/ColoredModal.vue';
import ModalPfe from './ModalPfe/ModalPfe.vue';
export default {
  components: {
    ModalImportEnseignant,
    ButtonDisplay,
    GenreComponent,
    ModalEnseignant,
    CarteDetailsEnseignant,
    KebabMenu,
    ColoredModal,
    GradientButton,
    SearchField,
    ModalPfe,
  },
  props: {
    etab: {
      Type: String,
      default: 'etab',
    },
    idUser: {
      Type: Number,
    },
  },
  data() {
    return {
      isEditAllowed: editAllowed,
      headers: [
        {title: 'Nom', key: 'nom', align: 'center'},
        {title: 'Prénom', key: 'prenom', align: 'center'},
        {title: 'Département', key: 'departement.label', align: 'center'},
        {title: 'Genre', key: 'gendre', align: 'center'},
        {title: 'Grade', key: 'grade_trad', align: 'center'},
        {title: 'Situation', key: 'situation', align: 'center'},
        {title: 'Actions', key: 'id', align: 'center'},
      ],
      enseignants: [],
      editionIcon,
      page: 1,
      itemsPerPage: 5,
      perPageOptions: [
        {title: '5 par page', value: 5},
        {title: '10 par page', value: 10},
        {title: '20 par page', value: 20},
        {title: '50 par page', value: 50},
      ],
      searchQuery: '',
      info,
      kebabIcon,
      upload,
      departement: '',
      decryptedType: '',
      modalOpen: false,
      selectedEnseignant: '',
      openKebabMenu: false,
      selectedItemId: null,
      updatedEnseignant: null,
      confirmSupressionOpen: false,
    };
  },

  mounted() {
    this.getDepartementByIdDirecteur().then(() => {
      this.fetchEnseignants();
    });
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
  },
  methods: {
    async refreshData() {
      await this.fetchEnseignants(true);
    },
    async getDepartementByIdDirecteur() {
      const id_directeur = parseInt(this.idUser);
      try {
        const response = await apolloClient.query({
          query: GETDEPARTEMENT_BY_ID_DIRECTEUR,
          variables: {
            id_directeur: id_directeur,
          },
        });
        const departement = response.data.getDepartementByIdDirecteur.label;
        this.departement = departement;
      } catch (error) {
        console.error('Erreur de recuperation departement:', error);
      }
    },
    async fetchEnseignants(refetch = false) {
      try {
        const response = await apolloClient.query({
          query: FETCH_ENSEIGNANTS,
          variables: {
            etab: this.etab,
            departement: this.departement,
          },
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        });
        const enseignants = response.data.getAllEnseignants;
        this.enseignants = enseignants;
      } catch (error) {
        console.error('Error fetching Enseignants:', error);
      }
    },
    async getEnseignantById(id) {
      try {
        const response = await apolloClient.query({
          query: GET_ENSEIGNANT_BY_ID,
          variables: {
            id: id,
          },
          fetchPolicy: 'no-cache',
        });
        this.selectedEnseignant = response.data.getEnseignantById;
      } catch (error) {
        console.error('error fetching enseignant by id :', error);
      }
    },
    async deleteEnseignant(id) {
      try {
        await apolloClient.mutate({
          mutation: DELETE_ENSEIGNANT,
          variables: {
            id: id,
          },
        });
        this.refreshData();
        this.openKebabMenu = false;
        this.confirmSupressionOpen = false;
      } catch (error) {
        console.error(error);
      }
    },
    toggleKebabMenu(id) {
      this.selectedItemId = id;
      this.openKebabMenu = !this.openKebabMenu;
    },
    async openEditModal() {
      this.openKebabMenu = false;
      await this.getEnseignantById(this.selectedItemId);
      this.$refs.ModalEnseignant.openEditionModal(
        this.selectedItemId,
        this.selectedEnseignant
      );
    },
    toggleSupression() {
      this.confirmSupressionOpen = !this.confirmSupressionOpen;
    },
    handleSearch(query) {
      this.searchQuery = query;
    },
    exporterEnseignants() {
      window.open(
        process.env.VUE_APP_API_URL +
          '/Reporting.php?token=' +
          localStorage.getItem('token') +
          '&target=enseignants'
      );
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.filteredEnseignants.length / this.itemsPerPage);
    },
    menuItems() {
      return [
        {label: 'Editer', method: this.openEditModal},
        {label: 'Supprimer', method: this.toggleSupression},
      ];
    },
    filteredEnseignants() {
      let filtered = this.enseignants;
      if (this.searchQuery) {
        const query = String(this.searchQuery).toLowerCase().trim();
        const queryAsNumber = parseInt(query, 10);
        filtered = filtered.filter(
          enseignant =>
            (enseignant.nom && enseignant.nom.toLowerCase().includes(query)) ||
            (enseignant.cin_off &&
              enseignant.cin_off.toLowerCase().includes(query)) ||
            (enseignant.nom_ar &&
              enseignant.nom_ar.toLowerCase().includes(query)) ||
            (enseignant.prenom &&
              enseignant.prenom.toLowerCase().includes(query)) ||
            (enseignant.prenom_ar &&
              enseignant.prenom_ar.toLowerCase().includes(query)) ||
            (enseignant.situation &&
              enseignant.situation.toLowerCase().includes(query)) ||
            (enseignant.mail &&
              enseignant.mail.toLowerCase().includes(query)) ||
            enseignant.cin_off === queryAsNumber
        );
      }
      return filtered;
    },
  },
};
</script>
<style scoped>
.banner-bg {
  background-image: url('../../assets/officeDesk.png');
}

.v-data-table-header__content {
  font-weight: bold !important;
}

.v-data-table-header__content .libelle-header {
  font-weight: bold;
}

.v-table__wrapper th:first-child {
  visibility: visible !important;
}

.v-select-list .v-field__input {
  display: none !important;
}

.v-table__wrapper {
  border-radius: inherit;
}
</style>
