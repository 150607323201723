<template>
  <v-dialog v-model="modalOpen" max-width="530">
    <template v-slot:activator="{on}">
      <CreationButton v-on="on" @click="openModal" :type="decryptedType" />
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">
          <span v-if="id">Edition de matière</span>
          <span v-else> Ajouter à la liste des matières</span>
        </h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal" />
      </div>
      <div class="mt-4">
        <label class="mb-2">Libellé de matière</label>
        <input
          type="text"
          v-model="departmentLabel"
          class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none"
        />
        <span
          v-if="formSubmitted && validate(departmentLabel)"
          class="absolute -translate-y-4 flex items-center text-red-500 text-xs"
        >
          <img :src="redinfo" class="h-3 w-3" />
          {{ validate(departmentLabel) }}
        </span>
      </div>
      <div class="flex items-center">
        <div class="w-1/2 mr-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left"
            >Departement</label
          >
          <select
            v-model="selectedDepartmentId"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
            @change="handleDepartmentChange"
          >
            <option value="" disabled hidden>Veuillez sélectionner</option>
            <option
              v-for="departement in Departments"
              :key="departement.id"
              :value="departement.id"
            >
              {{ departement.label }}
            </option>
          </select>
          <span
            v-if="formSubmitted && validate(selectedDepartmentId)"
            class="absolute flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ validate(selectedDepartmentId) }}
          </span>
        </div>
        <div class="w-1/2">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left"
            >Semestre</label
          >
          <select
            v-model="selectedSemestre"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
            @change="handleSemestreChange"
          >
            <option value="" disabled hidden>Veuillez sélectionner</option>
            <option value="S1">Semestre 1</option>
            <option value="S2">Semestre 2</option>
          </select>
          <span
            v-if="formSubmitted && validate(selectedSemestre)"
            class="absolute flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ validate(selectedSemestre) }}
          </span>
        </div>
      </div>
      <div class="flex items-center">
        <div class="w-1/2 mr-4 mt-4">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left"
            >Natures des séances</label
          >

          <VSelect
            clearable
            chips
            v-model="nature"
            @change="handleNatureChange"
            label="Veuillez sélectionner"
            :items="seances.map(seance => seance.nature)"
            multiple
            variant="outlined"
          >
          </VSelect>
          <span
            v-if="formSubmitted && validate(nature)"
            class="absolute -translate-y-5 flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ validate(nature) }}
          </span>
        </div>
        <div class="w-1/2 relative">
          <label
            class="mb-2 block font-inter text-base font-normal leading-6 text-left"
            >Niveaux</label
          >
          <select
            v-model="selectedLevel"
            @click="toggleDropdown"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none appearance-none"
          >
            <option value="" disabled hidden>Veuillez sélectionner</option>
            <option value="L1">L1</option>
            <option value="L2">L2</option>
            <option value="L3">L3</option>
            <option value="M1">M1</option>
            <option value="M2">M2</option>
          </select>
          <span
            v-if="formSubmitted && validate(selectedLevel)"
            class="absolute flex items-center text-red-500 text-xs"
          >
            <img :src="redinfo" class="h-3 w-3" />
            {{ validate(selectedLevel) }}
          </span>
        </div>
      </div>
      <div>
        <label
          class="mb-2 block font-inter text-base font-normal leading-6 text-left"
          >Volume horaire</label
        >
      </div>
      <v-radio-group v-model="selectedValue" inline class="w-full">
        <v-layout class="flex gap-3 justify-stretch">
          <v-radio
            v-for="item in VOL_HORAIRES"
            :value="item"
            :key="item"
            color="indigo-darken-3"
            :label="item + ' H'"
            class="border border-gray-300 rounded-md text-gray-600 grow"
          ></v-radio>
        </v-layout>
      </v-radio-group>

      <div class="w-1/2">
        <label
          :class="`mb-2 block gap-2 ${
            specialites.length === 0 ? 'text-gray-400' : ''
          }`"
          >Spécialité</label
        >
        <select
          v-model="selectedSpecialite"
          :disabled="specialites.length === 0"
          class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
        >
          <option value="" disabled hidden>Veuillez sélectionner</option>
          <option
            v-for="specialite in specialites"
            :key="specialite.id"
            :value="specialite.id"
          >
            {{ specialite.label }}
          </option>
        </select>
        <span
          v-if="formSubmitted && validate(selectedSpecialite)"
          class="absolute flex items-center text-red-500 text-xs"
        >
          <img :src="redinfo" class="h-3 w-3" />
          {{ validate(selectedSpecialite) }}
        </span>
      </div>
      <v-card-actions class="gap-2 flex justify-end mt-2">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal"
        >
          Annuler
        </button>
        <button
          class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="addMatiere"
        >
          Valider
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import plus from '../../../assets/plus-sm.png';
import Button from '../../../assets/Button.png';
import gql from 'graphql-tag';
import apolloClient from '../../../apolloClient';
import redinfo from '../../../assets/redinfo.svg';
import CryptoJS from 'crypto-js';
import CreationButton from '../../Buttons/CreationButton/CreationButton.vue';
export default {
  name: 'ModalMatiere',
  components: {
    CreationButton,
  },
  data() {
    return {
      redinfo,
      modalOpen: false,
      plus,
      Button,
      departmentLabel: '',
      Departments: [],
      matieresTypesSeance: [],
      niveaux: [],
      Semestres: [],
      specialites: [],
      selectedSemestre: '',
      selectedDepartmentId: '',
      selectedLevel: '',
      selectedDepartment: null,
      selectedSeance: '',
      VOL_HORAIRES: [21, 31.5, 42],
      selectedValue: 21,
      selectedSpecialite: '',
      seances: [
        {id: 1, nature: 'tp'},
        {id: 2, nature: 'td'},
        {id: 3, nature: 'cours'},
        {id: 4, nature: 'cours integrés'},
      ],
      nature: [],
      dropdownOpen: false,
      formSubmitted: false,
      id: '',
      decryptedType: '',
    };
  },
  methods: {
    validate(value) {
      if (!value || value.length == 0) {
        return 'Valeur invalide !';
      }
      return '';
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.id = '';

      this.departmentLabel = '';
      this.selectedDepartmentId = '';
      this.selectedSemestre = '';
      this.selectedLevel = '';
      this.nature = [];
      this.selectedValue = 22.5;
      this.selectedSpecialite = '';
    },
    editItem(item) {
      this.modalOpen = true;
      this.id = item.id;
      this.departmentLabel = item.label;
      this.selectedDepartmentId = item.dep_id.toString();
      this.selectedSemestre = item.semestre;
      this.selectedLevel = item.niveau;
      this.selectedValue = item.volume_horaire;
      this.nature = item.natures;
      this.getSpecialites();
      this.selectedSpecialite = item.id_specialite;
    },
    async getDep() {
      try {
        const response = await apolloClient.query({
          query: gql`
            query departement_prs {
              getAllDepartements {
                id
                label
              }
            }
          `,
        });
        this.Departments = response.data.getAllDepartements;
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des départements :',
          error
        );
      }
    },
    async addMatiereGql() {
      await apolloClient.mutate({
        mutation: gql`
          mutation matiere_prs(
            $label: String
            $volume_horaire: Float
            $dep_id: Int
            $semestre: String
            $niveau: String
            $id_specialite: Int
            $nature: String
          ) {
            addMatiere(
              label: $label
              volume_horaire: $volume_horaire
              dep_id: $dep_id
              semestre: $semestre
              niveau: $niveau
              id_specialite: $id_specialite
              nature: $nature
            )
          }
        `,
        variables: {
          label: this.departmentLabel,
          volume_horaire: this.selectedValue,
          dep_id: parseInt(this.selectedDepartmentId),
          semestre: this.selectedSemestre,
          niveau: this.selectedLevel,
          id_specialite: this.selectedSpecialite,
          nature: this.nature.join(';'),
        },
      });

      this.departmentLabel = '';
      this.selectedDepartmentId = '';
      this.selectedSemestre = '';
      this.nature = [];
      this.selectedLevel = '';
      this.selectedValue = 22.5;
      this.selectedSpecialite = '';

      this.closeModal();
    },
    async addMatiere() {
      try {
        this.formSubmitted = true;
        if (!this.selectedDepartmentId) {
          throw new Error('Veuillez sélectionner un département.');
        }
        if (!this.selectedSemestre) {
          throw new Error('Veuillez sélectionner un semestre.');
        }
        if (!this.selectedLevel) {
          throw new Error('Veuillez sélectionner un niveau.');
        }
        if (this.id == '') {
          await this.addMatiereGql();
        } else {
          const updateResponse = await apolloClient.mutate({
            mutation: gql`
              mutation updateMatiere(
                $id: Int!
                $label: String!
                $volume_horaire: Float!
                $dep_id: Int!
                $semestre: String!
                $niveau: String!
                $id_specialite: Int!
                $nature: String!
              ) {
                updateMatiere(
                  id: $id
                  label: $label
                  volume_horaire: $volume_horaire
                  dep_id: $dep_id
                  semestre: $semestre
                  niveau: $niveau
                  id_specialite: $id_specialite
                  nature: $nature
                )
              }
            `,
            variables: {
              id: parseInt(this.id),
              label: this.departmentLabel,
              volume_horaire: parseFloat(this.selectedValue),
              dep_id: parseInt(this.selectedDepartmentId),
              semestre: this.selectedSemestre,
              niveau: this.selectedLevel,
              id_specialite: parseInt(this.selectedSpecialite),
              nature: this.nature.join(';'),
            },
          });
          if (!updateResponse.data.updateMatiere) {
            throw new Error(
              'Erreur lors de la suppression de la matière existante.'
            );
          }
        }
        this.closeModal();
        this.formSubmitted = false;
        this.$emit('update-table');
      } catch (error) {
        console.error("Erreur lors de l'ajout de la matière :", error);
      }
    },
    async getSpecialites() {
      try {
        const response = await apolloClient.query({
          query: gql`
            query specialite($id_dept: Int) {
              getSpecialitesByDepartement(id_dept: $id_dept) {
                id
                label
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            id_dept: parseInt(this.selectedDepartmentId),
          },
        });
        this.specialites = response.data.getSpecialitesByDepartement;
      } catch (error) {
        console.error(
          'Erreur lors de la récupération des spécialités :',
          error
        );
      }
    },
    handleNatureChange(selectedItems) {
      this.nature = selectedItems;
    },
    handleDepartmentChange() {
      this.getSpecialites();
    },
  },
  mounted() {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
    this.getDep();
  },
};
</script>
<style>
.v-field__input {
  min-height: 40px !important;
}
</style>
