<template>
  <div>
    <div class="flex min-h-screen">
      <LeftNavbarRouter
        :dataOpenSideBar="openSideBar"
        :clickHambuger="toggleSideBar"
        class="z-40 print:hidden top-0"
        :class="{'min-h-screen h-screen fixed': openSideBar}"
        :userType="user.type"
      />
      <div
        class="w-full flex-col sm:ms-0"
        :class="{'md:ms-64 print:ms-0': openSideBar}"
      >
        <TopNavbar
          :dataOpenSideBar="openSideBar"
          :clickHambuger="toggleSideBar"
          :nom="user.nom"
          :prenom="user.prenom"
          :type="user.type"
          class="print:hidden"
          @item-clicked="handleNavbarItemClick"
        >
          <DropDown
            :dataOpenDropdown="openDropdown"
            :clickDropdown="deconnecter"
          />
        </TopNavbar>
        <div>
          <Transition name="slide-fade">
            <slot v-if="isFullComponent()"></slot>
            <component
              :is="currentComponent"
              v-else-if="currentComponent !== 'EnseignantsList'"
            ></component>
            <EnseignantsList
              v-else
              :etab="etablissement"
              :idUser="idUser"
            ></EnseignantsList>
          </Transition>
          <FooterComponent class="pt-64 bg-gray-50" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNavbarRouter from '../../components/LeftNavbar/LeftNavbarRouter.vue';
import TopNavbar from '../../components/TopNavbar/TopNavbar.vue';
import FooterComponent from '../../components/FooterComponent/FooterComponent.vue';
import DepartementsList from '../../components/Departements/DepartementsList.vue';
import MatiereList from '../../SPA/Matieres/MatieresList.vue';
import EnseignantsList from '../Enseignants/EnseignantsList.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import EmploisDeTemps from '../EmploisDeTemps/EmploisDeTemps.vue';
import salle from '../Salles/SallesList.vue';
import ClassesList from '../Classes/ClassesList.vue';
import Parametre from '../Parametre/Parametre.vue';
import ProfilePage from '../Profile/ProfilePage.vue';
import DashBoard from '../DashBoard/DashBoard.vue';
import {getDecryptedType} from '../spa.services';

export default {
  name: 'App',
  components: {
    TopNavbar,
    FooterComponent,
    LeftNavbarRouter,
    DepartementsList,
    MatiereList,
    EnseignantsList,
    DropDown,
    EmploisDeTemps,
    salle,
    ClassesList,
    Parametre,
    ProfilePage,
    DashBoard,
  },
  props: {
    currentUrl: {
      type: String,
      default: '',
    },
  },
  watch: {
    $route(pathUrl) {
      if (window.innerWidth <= 768) {
        this.openSideBar = false;
        return;
      }
      if (
        pathUrl.path.includes('emploi-classe') ||
        pathUrl.path.includes('emploi-enseignant') ||
        pathUrl.path.includes('emploi-salle') ||
        pathUrl.path.includes('heures-supp')
      ) {
        this.openSideBar = false;
      } else {
        this.openSideBar = true;
      }
    },
  },
  data() {
    return {
      openSideBar: true,
      user: {nom: '', prenom: '', type: ''},
      currentComponent: '',
      etablissement: '',
      idUser: null,
      openDropdown: false,
    };
  },
  methods: {
    toggleSideBar() {
      this.openSideBar = !this.openSideBar;
    },
    isFullComponent() {
      return this.currentUrl !== '/' && this.currentUrl !== '/accueil';
    },
    handleNavbarItemClick(label) {
      switch (label) {
        case 'Gestion des départements':
          this.currentComponent = 'DepartementsList';
          break;
        case 'Gestion des matières':
          this.currentComponent = 'MatiereList';
          break;
        case 'Gestion des enseignants':
          this.currentComponent = 'EnseignantsList';
          break;
        case 'Emplois de temps':
          this.currentComponent = 'EmploisDeTemps';
          break;
        case 'Gestion des salles':
          this.currentComponent = 'salle';
          break;

        case 'Gestion des classes':
          this.currentComponent = 'ClassesList';
          break;
        case 'Paramétre':
          this.currentComponent = 'Parametre';
          break;
        case 'ProfilePage':
          this.currentComponent = 'ProfilePage';
          break;
        case 'Tableau de bord':
          this.currentComponent = 'DashBoard';
          break;
        default:
          this.currentComponent = '';
      }
    },
    closeSidebarOnSmallScreen() {
      if (window.innerWidth <= 768) {
        this.openSideBar = false;
      }
    },
    deconnecter() {
      localStorage.clear();
      this.$router.push('/');
    },
  },
  created() {
    this.closeSidebarOnSmallScreen();
    window.addEventListener('resize', this.closeSidebarOnSmallScreen);

    const token = localStorage.getItem('token');
    const nom = localStorage.getItem('nom');
    const prenom = localStorage.getItem('prenom');
    const etab = localStorage.getItem('etab');
    const idUser = localStorage.getItem('idUser');

    const type = getDecryptedType();
    if (!['dir', 'dir_etu', 'dir_dept', 'secretaire'].includes(type)) {
      localStorage.clear();
      this.$router.push('/');
    } else if (token && nom && prenom && type && etab && idUser) {
      this.user = {nom, prenom, type};
      this.etablissement = etab;
      if (type === 'dir_dept') {
        this.idUser = idUser;
      }
    } else {
      console.error('data not found');
    }
  },
  mounted() {
    if (this.$route.path.includes('emploi-classe')) {
      this.openSideBar = false;
    }
    if (this.$route.path.includes('emploi-enseignant')) {
      this.openSideBar = false;
    }
    if (this.$route.path.includes('emploi-salle')) {
      this.openSideBar = false;
    }
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateY(-10px);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0.7;
}
</style>
