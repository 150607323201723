<template>
  <div
    v-if="label"
    class="p-1 pr-4 flex w-fit items-center gap-1 border-0 rounded-full"
    :class="getColor('bg')"
  >
    <div
      :class="getColor('bgDark')"
      v-if="!hideFirstLetter"
      class="h-6 w-6 rounded-full flex items-center justify-center text-xs text-white"
    >
      {{ getFirstAndLastLetters().toUpperCase() }}
    </div>
    <div
      :class="`text-center ${getColor(
        'text'
      )} max-w-30 pl-2 text-sm overflow-hidden`"
    >
      <p
        class="truncate cursor-pointer"
        :title="label"
        @click="popupLabel(label)"
      >
        {{ label }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonDisplay',
  props: {
    label: {
      type: String,
      required: true,
    },
    hideFirstLetter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    popupLabel(label) {
      alert('Nom du département :' + label);
    },
    getFirstAndLastLetters() {
      const words = this.label.split(/['\s]+/);
      if (words.length === 1) {
        return words[0].charAt(0);
      } else {
        let firstLetter = words[0].charAt(0);
        let lastWord = words[words.length - 1];
        let lastLetter = lastWord.charAt(0);
        return firstLetter + lastLetter;
      }
    },
    getColor(type) {
      const labels = [
        ['a', 'e', 'i', 'm', 'q', 'u', 'y'],
        ['b', 'f', 'j', 'n', 'r', 'v'],
        ['c', 'g', 'k', 'o', 's', 'w'],
        ['d', 'h', 'l', 'p', 't', 'x'],
      ];
      let index = labels.findIndex(list => {
        return list.includes(this.label.trim().charAt(0).toLowerCase());
      });

      if (index < 0) {
        index = 0;
      }

      const bgColors = [
        'bg-teal-50',
        'bg-orange-50',
        'bg-lime-50',
        'bg-amber-50',
      ];
      const bgDarkColors = [
        'bg-teal-500',
        'bg-orange-500',
        'bg-lime-500',
        'bg-amber-500',
      ];
      const textColors = [
        'text-teal-500',
        'text-orange-500',
        'text-lime-500',
        'text-amber-500',
      ];

      let colors;
      switch (type) {
        case 'bg':
          colors = bgColors;
          break;
        case 'bgDark':
          colors = bgDarkColors;
          break;
        case 'text':
          colors = textColors;
          break;
        default:
          colors = bgColors;
          break;
      }
      return colors[index] || bgColors[index];
    },
  },
};
</script>
